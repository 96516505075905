<template>
  <div class="info">
    <header class="live-meta-header">
      <button class="live-meta-close" @click="$parent.closeMeta()">
        <span class="material-icons-outlined">close</span>
      </button>
      <button class="live-photo">
        <img
          :src="$live.profilePics[room.roomId]"
          v-if="room && $live.profilePics[room.roomId]">
        <span class="material-icons-outlined">person</span>
      </button>
      <div class="live-header-name" v-if="room !== null">
        {{$parent.currentName}}
      </div>
    </header>
    <b-tabs fill v-model="$parent.tabMetaIndex" class="live-meta-tabs">
      <b-tab active>
        <template #title>
          <span class="material-icons-outlined">info</span>
          <span class="sr-only">Informações do Contato</span>
        </template>

        <div class="live-info" v-if="room !== null">
          <div class="d-flex align-items-center">
            <h3 class="flex-grow-1">Informações do Contato</h3>
            <button class="btn btn-light" @click="editOpen">
              <span class="material-icons-outlined">edit_note</span>
            </button>
          </div>
          <!-- <template v-for="(value, key, index) in room.contact"> -->
          <div class="omni-edit-contact" v-if="editMode">
            <div class="qt-loader" v-if="editSaving">
              <div>
                <b-spinner variant="primary" type="grow" small></b-spinner>
                <b-spinner variant="primary" type="grow" small></b-spinner>
                <b-spinner variant="primary" type="grow" small></b-spinner>
              </div>
            </div>
            <template v-for="(item, index) in editFields">
              <div :key="index" class="form-group">
                <label :for="`edit${index}`">{{item.label}}</label>
                <template v-if="index === 'gender'">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender" value="female">Feminino</b-form-radio>
                    <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender" value="male">Masculino</b-form-radio>
                    <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender" value="custom">Personalizado</b-form-radio>
                    <input
                      class="form-control mb-2 mt-1"
                      type="text"
                      value=""
                      placeholder="Gênero personalizado..."
                      v-model="customGender"
                      v-if="gender === 'custom'">
                    <b-form-radio v-model="gender" :aria-describedby="ariaDescribedby" name="gender" value="">Não informar</b-form-radio>
                  </b-form-group>
                </template>
                <textarea
                  class="form-control"
                  v-model="item.value"
                  v-else-if="item.type === 'textarea'"></textarea>
                <input
                  :id="`edit${index}`"
                  :type="item.type || 'text'"
                  class="form-control"
                  v-model="item.value"
                  v-else>
              </div>
            </template>
            <div class="d-flex justify-content-between py-2">
              <button class="btn btn-outline-primary" @click="editCancel">Cancelar</button>
              <button class="btn btn-primary" @click="editSave">Salvar</button>
            </div>
          </div>
          <template v-else>
            <template v-for="(field, key) in editFields">
              <div :key="key" class="field-edit" v-if="field.hide !== true && field.value">
                <p :title="field.label">
                  <span class="material-icons-outlined mr-2">
                    {{field.icon}}
                  </span>
                  <template v-if="key === 'mobileNumber' || key === 'phone'">
                    {{$live.maskPhone(field.value)}}
                  </template>
                  <template v-else-if="key === 'gender' && field.value === 'female'">
                    Feminino
                  </template>
                  <template v-else-if="key === 'gender' && field.value === 'male'">
                    Masculino
                  </template>
                  <template v-else>
                    {{field.value}}
                  </template>
                </p>
              </div>
            </template>
            <div class="field-edit" v-if="editFields.address.value">
              <p>
                <span class="material-icons-outlined mr-2">location_on</span>
                {{editFields.address.value}}, {{editFields.number.value}}, {{editFields.complement.value}}, {{editFields.district.value}},
                {{editFields.city.value}} - {{editFields.state.value}}
              </p>
            </div>
            <div class="field-edit" v-if="editFields.description">
              <h3>Descrição</h3>
              <p>
                {{editFields.description.value}}
              </p>
            </div>
          </template>
          <!-- <pre class="xsr-only">{{room}}</pre> -->

          <!-- <pre>x:{{editFields}}</pre>
          <pre>y:{{room.contact}}</pre> -->
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="material-icons-outlined">feedback</span>
          <span class="sr-only">Detalhes do atendimento</span>
        </template>

        <div class="live-info">
          <h3>Informações do atendimento</h3>
          <template v-if="room.protocol">
            <div class="d-flex align-items-center mb-3">
              <strong>
              Protocolo:
              </strong>
              <input type="text" class="form-control flex-grow-1" :value="room.protocol" readonly>
              <button
                class="btn bg-light btn-copy"
                type="button"
                @click="$parent.copyData('Protocolo copiado', room.protocol)">
                <span class="material-icons-outlined">content_copy</span>
              </button>
            </div>
            <div class="d-flex mb-3">
              <div class="mr-2">
                <span class="material-icons-outlined">sms_failed</span>
              </div>
              <div class="flex-grow-1">
                <div>
                  <template v-if="room.channel === 'whatsapp' || room.channel === 'whatsapp-java'">
                    WhatsApp
                  </template>
                  <template v-else-if="room.channel === 'widget' || room.channel === 'widget-java'">
                    Widget
                  </template>
                  <template v-else>{{room.channel}}</template>
                </div>
                <div v-if="room.origin">
                  <template v-if="room.channel === 'whatsapp' || room.channel === 'whatsapp-java'">
                    {{room.origin.replace(/^(\d{2})(\d{3})(\d{3})(\d{4,})$/, '+$1 $2 $3 $4')}}
                  </template>
                  <template v-else>{{room.origin}}</template>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3" v-if="room.department">
              <div class="mr-2">
                <span class="material-icons-outlined">badge</span>
              </div>
                <div>
                <strong>Departamento:</strong> {{room.department}}
              </div>
            </div>
            <div class="d-flex align-items-center mb-3" v-if="room.session">
              <div class="mr-2">
                <span class="material-icons-outlined" :class="{
                  'text-success': room.session === 'opened',
                  'text-warning': room.session === 'closing',
                  'text-danger': room.session === 'closed',
                }">incomplete_circle</span>
              </div>
              <div>
                <strong>Sessão:</strong> {{$parent.sessionMinutes}}
              </div>
              <div>
              </div>
              <div class="session-time">
                <span class="badge">
                  <template v-if="room.session === 'opened'">Aberta</template>
                  <template v-else-if="room.session === 'closing'">Encerrando</template>
                  <template v-else-if="room.session === 'closed'">Encerrada</template>
                </span>
              </div>
            </div>
          </template>
          <dl>
            <!-- <template v-if="room.protocol && !room.fromIdle"> -->
            <template v-if="room && !room.fromIdle && !room.fromOffline">
              <dt>Tabulação</dt>
              <!-- <pre>{{$live.tabs}}</pre> -->
              <dd class="mb-3" v-if="$live.tabs.length === 0">Não há tabulações cadastradas</dd>
              <dd class="mb-3" v-else>
                <div class="qt-loader qt-loader-mini" v-if="$parent.changingTabRoom">
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                </div>
                <select-input
                  class="yu-custom-select"
                  label="name"
                  v-model="$parent.currentTab"
                  :reduce="(tab) => tab.id"
                  :options="$live.tabs"
                  :clearable="false"
                  :disabled="$parent.roomClosed && !!$parent.currentTab"
                  :loading="this.$live.tabsLoading"
                  @input="$parent.updateTab"
                  v-else />
                <!-- :disabled="isManager" -->
              </dd>
            </template>
            <template v-if="room.fromOffline">
              <template v-if="room.solved">
                <p><i class="fas fa-check"></i> Resolvido</p>
              </template>
              <template v-else>
                <button class="btn live-manager-enter bg-danger mark-as-solved" @click="markAsSolved()">
                  <i class="fas fa-check"></i> Marcar como resolvido
                </button>
              </template>
            </template>
            <!-- <template v-else-if="$live.accountTags && $live.accountTags.length && !room.fromIdle"> -->
            <template v-else-if="!room.fromIdle">
              <dt>Tags</dt>
              <dd class="mb-3 tags">
                <select-input
                  taggable
                  multiple
                  push-tags
                  label="tags"
                  v-model="room.tags"
                  :options="$parent.filteredTags ? $parent.filteredTags : $parent.availableRoomTags"
                  :reduce="(tag) => tag"
                  :disabled=" $parent.roomClosed || $parent.currentTab === null || $parent.currentTab === ''"
                  @input="$parent.updateTags" />
                <!-- <div class="sr-only">
                  <pre>room.tags: {{room.tags}}</pre>
                  <pre>room.originalTags: {{room.originalTags}}</pre>
                </div> -->
              </dd>
            </template>
            <!-- <pre>room.tags: {{room.tags}}</pre>
            <pre>$live.accountTags: {{$live.accountTags}}</pre>
            <pre>availableRoomTags: {{$parent.availableRoomTags}}</pre> -->
            <template v-if="!room.fromIdle">
              <dt>Inicio do atendimento</dt>
              <dd class="mb-3">
                {{room.createdAt | formatDate24}}
              </dd>
              <template v-if="$parent.roomClosed">
                <dt>Encerramento do atendimento</dt>
                <dd class="mb-3">
                  {{room.closedAt | formatDate24}}
                </dd>
              </template>
            </template>
          </dl>
        </div>
      </b-tab>
      <b-tab @click="associativeHistory()">
        <template #title>
          <span class="material-icons-outlined">history</span>
          <span class="sr-only">Histórico do contato</span>
        </template>
        <!-- {{room.roomId}}
        {{$live.associativeTranscriptsTotal}}
        {{$live.associativeTranscriptsTotal[room.roomId]}} -->
        <div class="live-info" v-if="room !== null">
          <h3>Histórico do contato</h3>
          <div class="qt-loader qt-loader-mini" v-if="$live.associativeTranscriptsLoading[removeRoomPrefix(room.roomId)]">
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
          </div>
          <div class="p-20" v-else-if="!$live.associativeTranscriptsTotal[removeRoomPrefix(room.roomId)] || $live.associativeTranscriptsTotal[removeRoomPrefix(room.roomId)] === 0">
            Não há histórico
          </div>
          <template v-else>
            <template v-for="history of $live.associativeTranscriptsHistory">
              <div
                class="live-history-item card"
                :key="history.id"
                v-if="room !== null && history.parentRoomId.indexOf(removeRoomPrefix(room.roomId)) !== -1">
                <button class="copy btn bg-light" type="button" @click="$parent.copyData('Protocolo copiado', history.protocol)">
                  <span class="material-icons-outlined">copy</span>
                </button>
                <button
                  class="item"
                  :class="{
                    active: history.roomId === currentRoomId
                  }"
                  type="button"
                  @click="chooseTranscript(history)"
                >
                  <h4>
                    <i class="fas fa-globe" v-if="history.channel === 'widget'"></i>
                    <i class="fab fa-facebook-messenger" v-else-if="history.channel === 'messenger'"></i>
                    <i class="fab fa-whatsapp" v-else-if="history.channel === 'whatsapp'"></i>
                    <i class="fab fa-whatsapp" v-else-if="history.channel === 'whatsapp-java'"></i>
                    <i class="fab fa-google" v-else-if="history.channel === 'rcs'"></i>
                    <i class="fab fa-google" v-else-if="history.channel === 'rcs-google'"></i>
                    <i class="fab fa-telegram" v-else-if="history.channel === 'telegram'"></i>
                    <i class="fab fa-instagram" v-else-if="history.channel === 'instagram'"></i>
                    <i class="fas fa-envelope" v-else-if="history.channel === 'email'"></i>
                    <GoogleBusinessIcon v-else-if="history.channel === 'google-business'" />
                    <i class="fas fa-question" v-else></i>
                    <strong class="ml-2">Protocolo:</strong> {{history.protocol}}
                  </h4>
                  <!-- <p v-html="$live.markdown(history.lastMessage)"></p> -->
                  <div class="d-flex">
                    <div class="mr-2">
                      <span class="material-icons-outlined">history_toggle_off</span>
                    </div>
                    <div class="flex-grow-1">
                      <p>Ínicio do atendimento</p>
                      <time :title="history.createdAt">
                        {{history.createdAt | fullDate}}
                      </time>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="mr-2">
                      <span class="material-icons-outlined">history_toggle_off</span>
                    </div>
                    <div class="flex-grow-1">
                      <p>Ínicio do atendimento</p>
                      <time :title="history.lastMessageSentAt">
                        {{history.lastMessageSentAt | fullDate}}
                      </time>
                    </div>
                  </div>
                </button>
                </div>
            </template>
          </template>
        </div>
      </b-tab>
      <b-tab v-if="$parent.hasFiles">
        <template #title>
          <span class="material-icons-outlined">collections</span>
          <span class="sr-only">Arquivos</span>
        </template>

        <div class="live-grid-files p-2">
          <h4>Mídias, Áudios e Documentos</h4>
          <div v-if="$parent.currentFiles !== null">
            <template v-for="(files, type) in $parent.currentFiles">
              <div class="mt-4" :key="type">
                <h4 class="d-flex justify-content-between">
                  <span v-if="type === 'audio'">Áudios</span>
                  <span v-else-if="type === 'video'">Vídeo</span>
                  <span v-else-if="type === 'image'">Imagens</span>
                  <span v-else>Documentos</span>
                  <span class="mr-2">{{files.length}}</span>
                </h4>
                <div class="row files">
                  <div class="col-4" v-for="(file, index) in files" :key="index">
                    <button
                      @click="$parent.showFile(file, type)"
                      v-if="type === 'video' || type === 'audio' || type === 'image'">
                      <video
                        :src="file.uri"
                        :type="file.media_type"
                        v-if="type === 'video'"></video>
                      <div class="live-file-doc" v-else-if="type === 'audio'">
                        {{file.uri | fileExtension}}
                      </div>
                      <img
                        :src="file.uri"
                        v-else-if="type === 'image'" />
                      <i class="fas fa-video" v-if="type === 'video'"></i>
                      <i class="fas fa-image" v-else-if="type === 'image'"></i>
                      <i class="fas fa-headphones" v-else-if="type === 'audio'"></i>
                      <span>{{file.name}}</span>
                      <!-- <span>{{file.uri | basename}}</span> -->
                    </button>
                    <a :href="file.uri" target="_blank" v-else>
                      <div class="live-file-doc">
                        {{file.uri | fileExtension}}
                      </div>
                      <i class="fas fa-file"></i>
                      <span>{{file.name}}</span>
                      <!-- <span>{{file.uri | basename}}</span> -->
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-else>
            Não há arquivos
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Vue from 'vue';
import SelectInput from '@/components/SelectInput.vue';

import Swal from 'sweetalert2';

export default {
  name: 'OmniInfo',
  components: {
    SelectInput,
  },
  data() {
    return {
      editMode: false,
      editSaving: false,
      editFieldsStash: [],
      gender: null,
      customGender: null,
      editFields: {
        firstName: {
          label: 'Nome',
          value: '',
          icon: 'person',
          maxLength: 80,
          hide: true,
        },
        lastName: {
          label: 'Sobrenome',
          value: '',
          icon: 'person',
          maxLength: 80,
          hide: true,
        },
        birthDate: {
          label: 'Nascimento',
          value: '',
          icon: 'calendar_today',
          type: 'date',
        },
        gender: {
          label: 'Gênero',
          value: '',
          icon: 'male',
        },
        email: {
          label: 'e-mail',
          value: '',
          icon: 'email',
          type: 'email',
        },
        mobileNumber: {
          label: 'Celular',
          value: '',
          icon: 'call',
          type: 'phone',
        },
        phone: {
          label: 'Fixo',
          value: '',
          icon: 'call',
          type: 'phone',
        },
        country: {
          label: 'País',
          value: '',
          icon: 'outlined_flag',
        },
        zip: {
          label: 'CEP',
          value: '',
          icon: 'location_on',
          type: 'number',
          hide: true,
        },
        state: {
          label: 'Estado',
          value: '',
          icon: 'location_on',
          hide: true,
        },
        city: {
          label: 'Cidade',
          value: '',
          icon: 'location_on',
          hide: true,
        },
        district: {
          label: 'Bairro',
          value: '',
          icon: 'location_on',
          hide: true,
        },
        address: {
          label: 'Endereço',
          value: '',
          icon: 'location_on',
          hide: true,
        },
        number: {
          label: 'Numero',
          value: '',
          icon: 'location_on',
          type: 'number',
          hide: true,
        },
        complement: {
          label: 'Completo',
          value: '',
          icon: 'location_on',
          hide: true,
        },
        company: {
          label: 'Empresa',
          value: '',
          icon: 'business',
        },
        description: {
          label: 'Descrição',
          value: '',
          icon: 'person',
          type: 'textarea',
          hide: true,
        },
      },
    };
  },
  filters: {
    fullDate(data) {
      return new Date(data).toLocaleString();
    },
  },
  computed: {
    currentRoomId() {
      return this.$parent?.actualRoom?.roomId;
    },
  },
  props: ['room'],
  mounted() {
    this.loadContact();
  },
  watch: {
    room(newVal) {
      this.editCancel();
      this.loadContact();
    },
    gender(newVal) {
      if (newVal === 'custom') {
        this.editFields.gender.value = this.customGender;
      } else {
        this.editFields.gender.value = newVal;
      }
    },
    customGender(newVal) {
      this.editFields.gender.value = newVal;
    },
  },
  methods: {
    async loadContact() {
      const contact = this.room?.contact;

      if (!contact?.id) {
        setTimeout(() => this.loadContact(), 1000);
        return;
      }

      console.log('loadContact', JSON.stringify(contact));

      if (!contact) return;

      Object.keys(this.editFields).forEach((key) => {
        const data = this.editFields?.[key];
        const value = contact?.[key];

        if (data && value) {
          data.value = value;
          this.$set(data, 'value', value);
        }
      });

      const gender = this.editFields.gender.value;

      this.gender = null;
      this.customGender = null;

      if (gender === 'female' || gender === 'male') {
        this.gender = gender;
      } else if (gender) {
        this.gender = 'custom';
        this.customGender = gender;
      }

      console.info('OmniInfo', this.editFields, contact);

      const cloned = Object.assign(this.editFields);

      this.editFields = cloned;

      this.$set(this, 'editFields', cloned);
    },
    updateContact() {
      const contact = this.room?.contact;

      if (!contact) return;

      Object.keys(this.editFields).forEach((key) => {
        const item = this.editFields?.[key];
        contact[key] = item.value;
        Vue.set(contact, key, item.value);
      });
    },
    editOpen() {
      // this.editFieldsStash = [...this.editFields];
      this.editMode = !this.editMode;
    },
    editCancel() {
      // this.editFields = this.editFieldsStash;
      this.editMode = false;

      Object.keys(this.editFields).forEach((key) => {
        this.editFields[key].value = '';
      });

      this.loadContact();
    },
    editSave() {
      this.editSaving = true;

      const payload = {};
      const contact = this.$parent?.currentRoom?.contact;

      Object.keys(this.editFields).forEach((key) => {
        const field = key.replace(/([A-Z])/g, '_$1').toLowerCase();
        let value = this.editFields?.[key]?.value;

        console.log('editFields', { field, value }, this.$parent?.currentRoom?.contact);

        if (key === 'phone' || key === 'mobileNumber') {
          value = value.replace(/\D+/g, '');
        }

        if (value) payload[field] = value;
      });

      this.$live.updateClientContact(this.room, payload).then(() => {
        this.$toast.show({
          title: 'Sucesso',
          content: 'Dados atualizados',
          type: 'success',
        });

        this.editMode = false;

        this.updateContact();
      }).catch((error) => {
        console.error(error);
        this.$toast.show({
          title: 'Erro',
          content: `${error}`,
          type: 'danger',
        });
      }).finally(() => {
        this.editSaving = false;
      });
    },
    associativeHistory() {
      console.log('associativeHistory', this.room);

      if (this.room) {
        this.$live.requestTranscripts(this.room);
      }
    },
    chooseTranscript(transcript) {
      if (transcript && transcript.roomId !== this.currentRoomId) {
        this.$live.requestTranscriptMessages(transcript);
      }
    },
    markAsSolved() {
      const room = this.room;

      if (!room) return;

      Swal.fire({
        title: 'Marcar como resolvido?',
        text: '',
        // icon: 'warning',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) this.$live.markAsSolved(room);
      });
    },
    removeRoomPrefix(roomId) {
      return (roomId.indexOf('spy:') === 0) ? roomId.substring(4) : roomId;
    },
  },
};
</script>

<style lang="scss" scoped>
.omni-edit-contact {
  .qt-loader {
    &::before {
      display: none;
    }

    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn-copy {
  padding: .2rem;
}

.live-info {
  .field-edit {
    .material-icons-outlined {
      font-size: 1rem;
      vertical-align: middle;
      color: #752de6 !important;
    }
  }
}

.info {
  height: 100%;

  &.live-meta-show {
    display: block;
  }

  &::v-deep .live-meta-tabs {
    height: calc(100% - 120px);

    .material-icons-outlined {
      font-size: 1.2rem;
    }

    .tab-content {
      height: calc(100% - 52px);
    }
  }

  &::v-deep .tab-pane {
    height: 100%;
  }

  &::v-deep .live-meta-tabs {
    .nav-tabs {
      margin: 0 auto;
      width: 230px;
      border: none !important;
    }

    .nav-tabs {
      padding: 1rem 0;

      .nav-item {
        text-align: center;

        .nav-link {
          border-radius: 19px;
          background: transparent;
          padding: 10px 0 0 0;
          width: 38px;
          height: 38px;
          margin: 0 auto;

          .material-icons-outlined, i {
            color: #752de6 !important;
          }

          &.active, &:hover {
            border: none !important;
            background-color: rgba(116, 45, 230, .19);
          }
        }
      }
    }
  }

  .session {
    margin-right: 8px;
    display: inline-block;
    background: #ccc;
    border-radius: 9px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }

  .session-time {
    vertical-align: middle;
  }

  @media (max-width: 1230px) {
    // box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    flex: 0 0 0;
    z-index: 100;
    position: fixed;
    top: 62px;
    right: 0;
    width: 0;

    & > * {
      min-width: 300px;
    }

    &.live-meta-show {
      box-shadow: 0 22px 25px 9px rgba(0,0,0,.2);
      height: calc(100% - 62px);
      width: 300px;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }

  .live-photo {
    padding: 0 !important;
    margin: 0 15px 0 0;
    background: #ccc;
    height: 72px;
    width: 72px;
    border: none;
    border-radius: 50%;
    font-size: 32pt;
    margin-bottom: 5px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border-radius: 40px;
    overflow: hidden;

    .material-icons-outlined {
      font-size: 1.5rem;
    }

    &, * {
      text-align: center;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.live-header-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  width: 100%;
  font-size: 13pt;
  font-weight: bold;
  margin: 0 auto;
}

.live-meta-header {
  & > div:first-child,
  & > div:first-child + div {
    height: 100%;
  }

  & > div:first-child {
    width: 60px;
    float: left;
    text-align: center;
    padding-top: 22px;
  }

  & > div:last-child {
    text-align: center;
  }

  & > div:first-child + div {
    float: right;
    width: 42px;
  }
}

.live-meta-header {
  height: auto !important;
  position: relative;
  text-align: center;
  border: none !important;
}

.live-no-files {
  padding: 2em;
  text-align: center;
  font-size: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-grid-files {
  padding: 0;
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 7px);

  h4 {
    font-weight: normal !important;
  }

  .files {
    margin-right: -5px;
    margin-left: -5px;

    & > div {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .col-4 {
    height: 100px;
    margin-bottom: .5rem;

    /*
    &:first-child button {
      border-radius: 14px 0 0 0;
    }

    &:first-child button {
      border-radius: 14px 0 0 0;
    }
    */

    & ~ .live-no-files {
      display: none;
    }
  }

  a, button {
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    background: #a0a0a0;
    display: block;
    cursor: pointer;

    & > img, & > audio, & > video {
      object-fit: cover;
    }

    & > img, & > audio, & > video, .live-file-doc {
      min-width: 100%;
      min-height: 100%;
      pointer-events: none;
    }

    .live-file-doc {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24pt;
      font-weight: bold;
      text-transform: uppercase;
    }

    & > i {
      font-size: 80%;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0,0,0,.2);
      position: absolute;
      bottom: 5px;
      left: 5px;
    }

    & > span {
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,.2);
      width: 100%;
      height: auto;
      padding: .5em;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 70%;
      text-align: right;
      overflow: hidden;
      direction: rtl;
      opacity: 0.001;
      color: #fff;
      font-weight: bold;
      transition: ease-out .2s;
      pointer-events: none;
    }

    &:hover > span {
      opacity: .99;
    }
  }
}

.live-info {
  padding: 14px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  font-size: .92rem;

  h3 {
    font-size: .92rem;
    font-weight: normal;
    margin: 0;
  }

  dl {
    padding: .4em 0;
    margin: 0;

    dt {
      font-size: 10pt;
    }

    dd {
      margin-bottom: 2em;
      padding: .2em .2em .2em .2em;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &, > * {
        font-size: 10pt;
      }
    }

    .copy {
      input {
        padding: 0;
        background: transparent;
        border: none;
        margin-right: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      button {
        border-radius: 50px !important;
        border: thin solid #ccc;
      }
    }
  }
}

.live-history-item {
  padding: 0;
  margin: .4em 1em .4em 0;
  position: relative;
  width: 97%;
  text-align: left;
  transition: .15s all ease;

  h4 {
    font-weight: normal;
    font-size: 9pt;
  }

  .copy {
    position: absolute;
    right: .2rem;
    top: .2rem;
    padding: .2rem .5rem;
  }

  .item {
    text-align: left;
    padding: .6em;
    background: transparent;
    border: none;

    &:hover, &.active {
      background: #ececec;
    }

    &.active {
      cursor: auto;
    }
  }

  p {
    margin: 0;
    padding: .05em 2em .05em 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  time {
    font-size: 80%;
    color: #c0c0c0;
    display: block;
  }

  .channel {
    border: thin solid #ccc;
    border-radius: 50px;
    height: 32px;
    width: 32px;
    position: absolute;
    right: -16px;
    top: 25%;
    background: #fff;
    text-align: center;

    & + p + p {
      color: #a1a1a1;
    }

    i {
      position: relative;
      margin-top: 50%;
      width: 16px;
      height: 16px;
      top: -8px;
    }
  }
}

.live-old-history {
  border: solid #f7d85c;
  border-width: thin 0;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #ffefad;

  .items {
    height: 60px;
  }

  .item-left {
    position: relative;

    & > i {
      font-size: 18pt;
    }
  }

  .item-right {
    border-bottom: none;
  }

  * {
    color: #bb9600;
  }
}

.live-history-item {
  &.active {
    border-color: #f7d85c;
    background: #ffefad;

    &:hover {
      background: #fde996;
    }

    * {
      color: #bb9600;
    }

    .channel {
      border-color: #bb9600;
    }
  }
}

.live-meta-close {
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16pt;

  @media (max-width: 1230px) {
    display: block;
  }
}

.material-icons-outlined {
  color: #788db4 !important;
}

.whatsapp-bg {
  background: #efeae2 url('/assets/img/chat-bg.png');
  margin: 1.25rem -1.25rem;
  padding: 1rem;
  pointer-events: none;
}

.whatsapp-message {
  display: inline-block;
  max-width: 65%;
  min-width: 224px;
}

.whatsapp-preview {
  margin: 0 0 .1rem 0;

  & > p {
    margin: 0;
  }
}

.qt-loader {
  text-align: center;
  padding: 32px;
}

.recording-write {
  text-align: center;

  &::v-deep .spinner-grow {
    margin: 0 .22em;
  }
}

.live-resume-contact {
  margin-top: 1em;

  &::v-deep > .card > .tabs {
    & > .card-header {
      padding-bottom: 0;

      & > .nav {
        margin-bottom: 0;
      }
    }
  }
}

.live-header-contact {
  margin-top: .32rem;
  font-size: .8rem;
}

.live-media-preview {
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;

  video {
    background: #333;
  }

  & > * {
    pointer-events: none;
    min-height: 16px;
    min-width: 16px;
    display: block;
  }
}

.live-conversation {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
}

.live-old-load {
  padding: 1em;
  text-align: center;
}

.live-transcript {
  height: calc(100% - 172px);
  overflow: hidden;
  overflow-y: auto;

  &.readonly, &.live-transcript-old {
    height: calc(100% - 80px);
    background: url('data:image/svg+xml,%3Csvg width="220px" height="220px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">%3Cg fill="%23eee">%3Cpath fill="none" d="M0 0H24V24H0z"/>%3Cpath d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/>%3C/g>%3C/svg>') bottom right no-repeat;
  }
}

.live-transcript-old + .live-transcript {
  height: 0 !important;
  padding: 0 !important;
}

.live-manager-actions {
  position: relative;
  height: 92px;
  text-align: right;

  &.live-resume-actions {
    width: 204px;
    position: absolute;
    bottom: 0;
    right: .5em;
    z-index: 1000;
  }

  width: 204px;
  position: absolute;
  bottom: 0;
  right: .5em;

  .live-manager-enter {
    color: #fff;
    background: #0E1421;
    padding: 1em 2em;
    border-radius: 50px;
    border: none;
    font-size: 9pt;
    display: block;
    width: 98%;
    height: 40px;
    white-space: nowrap;

    & ~ .live-manager-enter {
      margin-top: .3em;
    }

    &, i {
      vertical-align: middle;
    }

    i {
      font-size: 13pt;
      margin-right: .5em;
      color: #fff;
    }
  }
}

.live-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: normal;
  align-items: center;
  border: thin solid #e8e4e4;
  background: #fff;
  padding: 0 .5em;
  position: relative;
  z-index: 90;

  .material-icons-outlined {
    font-size: 20px;
  }

  @media (max-width: 800px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  & > * {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 40px;
    align-self: auto;
    order: 0;
    height: 45px;

    @media (max-width: 480px) {
      flex-basis: 45px;
    }
  }

  .live-file-preview {
    position: absolute;
    z-index: 90;
    left: 0;
    bottom: 90px;
    width: 100%;
    height: 60vh;
    max-height: 640px;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: #fff;
    border: 0 solid #e8e4e4;
    border-top-width: thin;
    box-shadow: 0 -31px 31px -22px rgba(0,0,0,.2);

    aside {
      min-width: 200px;

      img, audio, video {
        display: block;
        margin: 0 auto;
      }
    }

    &.live-record-audio {
      bottom: 0;
      z-index: 91;

      .record {
        text-align: center;

        .timer {
          font-size: 9pt;
        }

        .audio-finish {
          margin: 10px 0;
          border-radius: 48px;
          background: #f00;
          border: none;
          height: 92px;
          width: 92px;
          font-size: 32pt;
          box-shadow: 0 0 0 rgba(0,0,0,.02), 0 0 0 0 rgba(0,0,0,.06);
          animation-duration: .4s;
          animation-name: audioWave;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          i, & {
            color: #fff !important;
          }

          @keyframes audioWave {
            from {
              box-shadow: 0 0 0 0 rgba(0,0,0,.02), 0 0 0 0 rgba(0,0,0,.06);
            }

            to {
              box-shadow: 0 0 0 8px rgba(0,0,0,.2), 0 0 0 4px rgba(0,0,0,.2);
            }
          }
        }

        footer {
          font-size: 7pt;
        }

        .row {
          padding: 20px 0;
        }

        .row > div {
          padding: 0;
        }

        .audio-cancel, .audio-send {
          width: 100%;
        }
      }
    }

    .card-header {
      height: 72px;

      button {
        background: transparent;
        border: none;
        padding: 0;

        i {
          font-size: 16pt;
        }
      }
    }

    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 72px);
      position: relative;

      aside {
        img, object, video {
          position: relative;
          border: thin solid #e8e4e4;
          object-fit: contain;
          height: 100%;
          max-height: 240px;

          @media (max-height: 500px) {
            max-height: 150px;
          }

          @media (max-height: 420px) {
            max-height: 110px;
          }
        }

        .d-flex {
          @media (max-height: 420px), (max-width: 240px) {
            font-size: 8pt;
          }
        }
      }
    }
  }

  .live-file-preview-meta {
    font-size: 9pt;
  }

  .live-input-out {
    flex-grow: 1;
    flex-basis: auto;
    // height: 52px;
    padding: 0 12px;
    height: auto !important;
  }
}

.live-input {
  border: thin solid #edeef0;
  background: #f7f7f7;
  margin: 0 1em;
  border-radius: .2rem;
  margin-top: 20px;

  ::v-deep button > span, ::v-deep button > i {
    display: block;
  }

  .live-input-in {
    border-radius: 27px;
    position: relative;

    .live-input-textarea {
      width: calc(100% - 40px);
      border: none;
      background: transparent;
      // background: #fc0;
      margin: 0;
      resize: none;

      // padding: .5em 0 0 0;
      // max-height: 6em;

      padding: .4em 0 0 0;
      max-height: 3.2em;

      // font-size: .92rem;

      &[readonly] {
        color: #cdcdcd;
      }
    }

    .window-listing-sample-msgs {
      position: absolute;
      left: 1.2rem;
      bottom: .82rem;
      background: #fff;
      max-width: 320px;
      min-width: 100px;
      overflow: auto;
      overflow-x: hidden;
      max-height: 300px;
      display: none;

      &.show {
        display: block;
      }

      .notfound {
        font-size: 10pt;
      }
    }

    .list-group-item {
      padding: 0;
      font-size: 8pt;
      text-align: left;

      & > strong,
      & .sample-item,
      & > div > strong {
        padding: .35rem;
        display: block;
      }

      .sample-dones {
        position: relative;
        padding: .35rem;
        padding-top: 0;

        &::after {
          position: absolute;
          left: -5%;
          bottom: 0;
          content: "";
          height: 10px;
          width: 110%;
          box-shadow: 5px -5px 6px 0 rgba(255,255,255,0.5) inset;
        }
      }

      .sample-item {
        width: 92px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #c0c0c0;
      }
    }

    .filter-msgs {
      .input-group-prepend {
        & > .input-group-text {
          background: transparent;
        }
      }
    }

    .window-create-sample-msg {
      position: absolute;
      right: .32rem;
      bottom: .82rem;
      background: #fff;
      width: 420px;

      .window-create-sample-close {
        border: none;
        border-radius: 20px;
        background: #000;
        position: absolute;
        right: -15px;
        top: -15px;
        width: 32px;
        height: 32px;

        i {
          color: #fff;
        }
      }

      h1 {
        font-size: 12pt;
      }

      & > .relative {
        position: relative;
        padding: .7rem;
      }

      .qt-block-ui {
        position: absolute !important;
      }
    }

    .btn-create-sample-msg {
      background: transparent !important;
      border: none !important;
      height: auto !important;
      position: absolute;
      right: 0;
      top: 0;
      padding: .62rem .2rem;

      // i {
      //   color: #fff;
      // }

      & + textarea {
        width: calc(100% - 40px - 1.5rem);
      }
    }

    .sample-messages {
      height: 262px;
      overflow: auto;
      overflow-x: hidden;

      .list-group-item {
        text-align: left;

        & > div {
          max-height: 52px;
          overflow: hidden;
        }
      }
    }

    .new-sample {
      margin: 0 !important;

      * {
        font-size: 9pt !important;
      }

      textarea {
        min-height: 140px;
      }

      & > :first-child {
        padding: 0;
      }

      & > :last-child {
        max-height: 300px;
        overflow: auto;
        overflow-x: hidden;
      }

      .form-check-label {
        width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .form-check {
        & > input, & > label {
          vertical-align: middle;
        }

        & > label {
          margin-top: .1rem;
        }
      }

      .form-footer {
        & > :first-child {
          padding-right: .3rem !important;
        }

        & > :last-child {
          padding-left: .3rem !important;
        }
      }
    }
  }

  ::v-deep .emojis .popup-box-container button > span {
    display: block;
    height: 25px;
  }

  .live-templates, ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    width: 40px;
    height: 45px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}

/*
  .live-enter {
    float: right;
    height: 62px;
    width: 62px;
  }

  ::v-deep .emojis {
    float: left;
  }

  .live-templates, ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    float: left;
    width: 48px;
    height: 62px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}*/

.live-inner-header {
  border-bottom-width: thin;
  position: relative;

  .material-icons-outlined {
    color: #752de6 !important;
  }

  .live-status {
    font-size: 7pt;
    font-weight: bold;
    vertical-align: middle;
  }

  .live-back {
    font-size: 14pt;
    float: left;
    width: 42px;
    height: 100%;
    background: transparent;
    border: none;

    &:hover {
      background: #fcfcfc;
    }

    @media (min-width: 801px) {
      display: none;
    }
  }

  & > .live-photo {
    margin: 18px 10px 0 10px;
    float: left;
    height: 48px;
    width: 48px;
    position: relative;
    border-radius: 0;
    background: transparent;

    .live-channel {
      position: absolute;
      right: -2px;
      top: -2px;
      font-size: 8pt;
      height: 16px;
      width: 16px;
      background: #fff;
      border-radius: 16px;
    }

    button {
      background: #dfdfdf;
      border: none;
      border-radius: 48px;
      float: left;
      height: 100%;
      width: 100%;
      font-size: 12pt;
      padding: 0;
      overflow: hidden;
      border-radius: 24px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .live-header-info {
    float: left;
    padding-top: 14px;
    width: calc(100% - 210px);
    font-size: 12pt;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  .live-header-name {
    font-weight: bold;

    .sub {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media (max-width: 920px) {
    .live-header-info {
      width: calc(80% - 100px);
    }

    .live-header-tools-m {
      width: 32px;
      margin-right: 15px;

      ::v-deep button.dropdown-toggle {
        background: #fff !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }
      }
    }
  }
}

.live-history-header {
  text-align: right;
  padding: .5em;
  border: solid #f7d85c;
  border-width: thin 0;
  background: #ffefad;
  font-size: 9pt;

  button {
    background: rgba(0,0,0,.1);
    border-radius: 14px;
    border: none;
    padding: 2px 10px;
    margin-left: 6px;
    transition: background-color .3s ease;
    vertical-align: middle;

    &:hover {
      background: rgba(0,0,0,.3);
    }
  }
}

.live-header-tools {
  padding: 21px 5px 0 5px;
  float: right;
  width: 135px;
  text-align: right;

  @media (max-width: 920px) {
    display: none;
  }

  &::v-deep button, .menu-item {
    border: none !important;
    padding: 0 !important;
    border-radius: 50%;
    height: 36px !important;
    width: 36px !important;
    margin-left: 5px;
    vertical-align: middle;
  }

  .menu-item {
    display: inline-block;

    &::v-deep .dropdown {
      &, button {
        color: #788db4 !important;
        margin: 0 !important;
      }

      button {
        box-shadow: none !important;
        background: transparent !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }

        &:hover, &:active, &:focus {
          box-shadow: none !important;
          background: #fafafa !important;
        }
      }
    }
  }

  button::v-deep {
    box-shadow: none !important;
    background: transparent !important;

    &:hover, &:active, &:focus {
      box-shadow: none !important;
      background: #fafafa !important;
    }
  }
}

.live-header-tools-m {
  display: none;
  width: 20%;
  float: right;
  margin-top: 22px;
  text-align: center;

  @media (max-width: 920px) {
    display: block;
  }
}

.live-inner-input {
  border-top-width: thin;
}

.live-transcript {
  padding: 1.5em;
}

.live-message {
  font-size: 10pt;
  min-width: 240px;

  .live-agent {
    position: absolute;
    right: -10px;
    top: -10px;
    color: #fff;
    font-size: 7pt;
    text-transform: uppercase;
    padding: .5em !important;
    line-height: 1 !important;

    &.finished {
      background: #f00 !important;
    }
  }

  .live-message-box {
    min-width: 200px;
    max-width: 60%;
    float: left;

    .icon-fail {
      font-size: 8pt;
      color: #fff;
    }

    .live-message-source {
      padding: 4px 2px 20px 2px;
      font-size: 10pt;

      i {
        vertical-align: middle;
        margin-right: 2px;
      }
    }
  }

  .live-message-content {
    overflow-wrap: break-word;
    // word-break: break-all;
  }

  .live-message-content::v-deep {
    code {
      overflow-wrap: break-word;
      white-space: pre-wrap;
      color: inherit;
    }

    [data-interactive] {
      display: inline-block;
      position: relative;
      font-weight: 400;

      & > div {
        position: absolute;
        right: -45%;
        top: -82%;
        opacity: 0;
        transition: .2s all ease-out;

        & > button {
          box-shadow: 0 1px 1px 1px rgba(0,0,0,.1);
          border-radius: .1rem;
          border: thin solid #c1c1c1;
          background: #fff;
          color: #c1c1c1;
        }
      }

      &:hover > div {
        opacity: 1;
      }
    }
  }

  .live-message-inner {
    position: relative;
    border: 1px solid #d6dadd !important;
    background: #f5f5f5;
    padding: 14px 14px 32px 14px;
    border-radius: .24em;
    letter-spacing: normal !important;

    .live-message-opts {
      position: absolute;
      right: 10px;
      top: 10px;

      &::v-deep .dropdown-toggle {
        visibility: hidden !important;
        background: transparent !important;
        padding: .1em .4em;
        height: auto;
        line-height: 1;
      }
    }

    &:hover .live-message-opts {
      &::v-deep .dropdown-toggle {
        visibility: visible !important;
        background: transparent !important;
        padding: .1em .4em;
        height: auto;
        line-height: 1;
      }
    }

    & > picture {
      position: absolute;
      right: -10px;
      top: -10px;
      color: #fff;
      background: #cfcfcf;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 4px;
      pointer-events: none;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    & > strong {
      display: block;
      padding-bottom: .4em;
    }

    .live-message-details {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 80%;
    }
    .live-message-time {
      opacity: .7;
      margin-right: 5px;
    }
  }

  // &:not(.me) .live-message-inner {
  //   border-top-left-radius: 0;
  // }

  &.another-agent .live-message-inner {
    background: #f5eaf9 !important;
  }

  &.place-offline .live-message-inner {
    background: #f5eaf9 !important;
  }

  &.automatic .live-message-inner {
    background: #ffedc7 !important;
  }

  &.me .live-message-inner {
    background: #752de6 !important;
    border: none !important;
    color: #fff;
  }

  &.fail {
    .live-message-inner {
      background: var(--danger) !important;
      color: #fff;
    }
  }

  &.deleted {
    .live-message-content {
      color: #cfcfcf !important;

      .live-message-text {
        font-style: italic;
      }
    }
  }

  &.me .live-message-box, &.automatic .live-message-box {
    float: right;

    .live-message-source {
      text-align: right;
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .live-message-attachments {
    video, img, audio {
      max-height: 320px;
      max-width: 100%;
    }
  }

  &.upload {
    .live-message-box {
      .live-message-inner {
        padding-bottom: 14px;
        white-space: nowrap;

        > span {
          margin-right: 4px;
        }
      }

      text-align: center;
      min-width: 1px;
      max-width: 60%;
    }
  }
}

.toggle-meta {
  @media (min-width: 1231px) {
    display: none;
  }
}

.live-input-count {
  position: absolute;
  right: 0;
  bottom: -17px;
  font-size: 7pt;

  .exceed {
    font-weight: bold;
    color: #f00;
  }
}

.live-input-btn {
  cursor: pointer;
  padding: 0;
  float: right;
  width: 40px;
  height: 45px;
  border: none;
  background: transparent;
  font-size: 12pt;
  transition: .3s background ease;
  outline: none;
  color: #752de6 !important;

  &:not(:enabled) {
    opacity: .5;
  }

  /*
  &:enabled, .live-speak {
    background: #752de6;
  }
  */

  span {
    color: inherit !important;
  }
}

/*
#live-attach {
  float: left;
}
*/

#live-attach-input {
  display: none;
}

.live-external {
  position: relative;
  background: rgba(0,0,0,.15);
  border-radius: 3px;
  display: block;
  color: #fff;
  overflow: hidden;

  & > i {
    color: inherit;
    text-align: center;
    vertical-align: middle;
    width: 62px;
    font-size: 42pt;
    padding: 8px;
    display: inline-block;
  }

  .live-attach-name {
    vertical-align: middle;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 70px);
    display: inline-block;
    text-align: right;
    direction: rtl;
  }

  .live-attach-type {
    background: rgba(0,0,0,.15);
    padding: 3px 6px;
    clear: both;
  }
}

#live-attach ::v-deep {
  button {
    border: none !important;
    background: transparent !important;
    width: 40px !important;
    padding: 0 !important;
    height: 45px !important;
    font-size: 14pt;
    box-shadow: none !important;

    &:hover, &:active {
      background: #fcfcfc !important;
    }
  }

  ul {
    top: auto !important;
    left: 0 !important;
    right: auto !important;
    bottom: 50px !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  @media (max-width: 480px) {
    button {
      height: 58px !important;
      font-size: 14pt;
      padding: 0 15px;
    }

    ul {
      bottom: 38px !important;
    }
  }
}
.icon-live {
  font-size: 18pt;
  color: #c1c7cd;
  float: left;
  margin-top: 18px;
}
.fab-item-image{
  text-align: center !important;
}
.fab-item-image i{
  display: block;
  color: rgb(140, 140, 140);
  text-align: center;
  margin-top: 8px;
}

.fa-spinner {
  animation: rotation .32s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
